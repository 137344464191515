import React from "react"
import { Helmet } from "react-helmet-async"

const WorkableFeed = ({ dataTheme }) => {
  return (
    <section id="jobs" data-theme={dataTheme}>
      <div className="container container--xs margin-top--lg margin-bottom--lg margin---none">
        <div className="col col--12" style={{ margin: "0 auto" }}>
          <div className="text--component text--center margin-bottom--lg">
            <h2>Open positions</h2>
          </div>
          <div>
            <Helmet>
              <script
                // async="true"
                src="https://www.workable.com/assets/embed.js"
                type="text/javascript"
              ></script>
              <script async="true" type="text/javascript" charset="utf-8">
                {`
            whr(document).ready(function(){
            whr_embed(48589, {detail: 'titles', base: 'jobs', zoom: 'city', grouping: 'departments'})
            }) `}
              </script>
            </Helmet>
            <div id="whr_embed_hook"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkableFeed
