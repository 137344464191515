import React from "react"
import Image from "../../image"

const EmployersGrid = ({
  // imgSize,
  Position,
  // employers,
  employersImageGrid,
  // employersGrid,
  filename,
  alt,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--fullwidth margin-bottom--lg margin---none">
        <div className={`grid flex--center-x flex--center-y ${Position}`}>
          {employersImageGrid && <Image filename={filename} alt="" />}
          {/* {employersGrid && (
            <div className={`col col--8 col--sm-6 ${imgSize}`}>
              {employers &&
                employers.map(employer => (
                  <div
                    key={employer.alt}
                    className={"logo--sm "}
                    style={{ width: "100%" }}
                  >
                    <Image filename={employer.Filename} alt={employer.alt} />
                  </div>
                ))}
            </div>
          )} */}
        </div>
      </div>
    </section>
  )
}

export default EmployersGrid

// import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import BackgroundImage from "gatsby-background-image"
// // import Image from "../../image"

// const EmployersGrid = () => {
//   const data = useStaticQuery(
//     graphql`
//       query {
//         desktop: file(relativePath: { eq: "group.png" }) {
//           childImageSharp {
//             fluid(maxWidth: 2880) {
//               ...GatsbyImageSharpFluid_noBase64
//             }
//           }
//         }
//       }
//     `
//   )
//   const imageData = data.desktop.childImageSharp.fluid
//   return (
//     <section data-theme="white">
//       <div className="container container--fullwidth margin-bottom--lg margin---none">
//         <BackgroundImage
//           fluid={imageData}
//           alt="developers"
//           className="col col--12 fullwidth--sm hero-cover coverBackground"
//         />
//       </div>
//     </section>
//   )
// }

// export default EmployersGrid
