import React from "react"

import Image from "../../image"

const SuccessCards = ({
  title,
  text,
  cards,
  widthIcons,
  renderSubTitle,
  storybook,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        <div className="text--component text--center margin-bottom--md">
          <h2 className="margin-bottom--xs">{title}</h2>
          {text && (
            <p style={{ maxWidth: "800px", margin: "0 auto" }}>{text}</p>
          )}

          {renderSubTitle && (
            <h3 className="text--center margin-bottom--md margin-top--lg">
              {renderSubTitle}
            </h3>
          )}
        </div>
        <div className="grid grid--gap-md text--center">
          {cards &&
            cards.map(card => (
              <div key={card.Filename} className="col col--12 col--md-4">
                <div
                  className="card flex flex--wrap"
                  style={{
                    padding: "32px",
                    minHeight: "400px",
                    backgroundColor: "#f6f9fd",
                  }}
                >
                  <div className={widthIcons}>
                    {/* {storybook ? (
                      <img
                        src="https://via.placeholder.com/200x200"
                        alt="sb pic"
                      />
                    ) : (
                      <Image alt={card.alt} filename={card.Filename} />
                    )} */}
                    <Image
                      className="logo--md"
                      alt={card.alt}
                      filename={card.Filename}
                    />
                  </div>

                  <div
                    className="text--component flex flex--start margin-top--xs"
                    style={{ flexDirection: "column" }}
                  >
                    <h3 className="text--md">{card.title}</h3>
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}

          <div></div>
        </div>
      </div>
    </section>
  )
}
export default SuccessCards
