import React from "react"
import Image from "../../image"
// import dsa from "../../../images/"
// import pic4 from "../../../../static/pt-1.jpg"
// import pic1 from "../../../../static/pt-2.jpg"
// import pic2 from "../../../../static/pt-3.jpg"
import Swiper from "react-id-swiper"
// import { useStaticQuery, graphql } from "gatsby"
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper"
import "swiper/components/pagination/pagination.min.css"

import { Helmet } from "react-helmet-async"
// import Img from "gatsby-image"
SwiperCore.use([Autoplay, EffectFade, Pagination])

const sliderParams = {
  slidesPerView: 0,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  fadeEffect: { crossFade: true },
  speed: 500,
  autoplayDisableOnInteraction: true,

  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
}

const EmployersSlider = () => {
  return (
    <section data-theme="white">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.css"
        />{" "}
        <script src="https://unpkg.com/swiper/swiper-bundle.js" />
      </Helmet>
      <div className="container container--md margin-top--lg margin-bottom--lg margin---none">
        <Swiper {...sliderParams} style={{ width: "100%!important" }}>
          <div>
            <div className="grid grid-gap--lg flex--center flex--space-between-md">
              <div className="col col--12 col--sm-5">
                <Image
                  alt="dev"
                  filename="emmaGray.jpg"
                  style={{ width: "100%!important", borderRadius: "8px" }}
                />
              </div>
              <div className="col col--12 col--sm-5">
                <div>
                  <p className="margin-bottom--md text--md text--bold">
                    "I really enjoy the broad spectrum of my work and I'm
                    seriously learning new things every day. I feel like the
                    company is truly caring about the employees and we have such
                    a thrilling journey ahead."
                  </p>
                  <p className="color--electric-blue">Emma</p>
                  <p>Data Scientist</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-gap--lg flex--center flex--space-between-md">
              <div className="col col--12 col--sm-5">
                <Image
                  alt="dev2"
                  filename="tequamGray.jpg"
                  style={{ width: "100%!important", borderRadius: "8px" }}
                />
              </div>
              <div className="col col--12 col--sm-5">
                <div>
                  <p className="margin-bottom--md text--md text--bold">
                    "I'm very motivated by the wonderful feedback we get from
                    our patients and that we have all competencies in-house to
                    influence the whole product process."
                  </p>
                  <p className="color--electric-blue">Tequam</p>
                  <p>App Engineer</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-gap--lg flex--center flex--space-between-md">
              <div className="col col--12 col--sm-5">
                <Image
                  alt="dev3"
                  filename="axelGray.jpg"
                  style={{ width: "100%!important", borderRadius: "8px" }}
                />
              </div>
              <div className="col col--12 col--sm-5">
                <div>
                  <p className="margin-bottom--md text--md text--bold">
                    "We work in quick iterations and invest a lot of time and
                    effort in learning and exploring things from different
                    angles, which I really appreciate as an engineer."
                  </p>
                  <p className="color--electric-blue">Axel</p>
                  <p>Backend Engineer</p>
                </div>
              </div>
            </div>
          </div>
        </Swiper>

        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"></div>
      </div>
    </section>
  )
}

export default EmployersSlider
