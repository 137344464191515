import React from "react"
import Button from "../../Button"

const Text = ({
  title,
  text,
  download,
  downloaddataGoal,
  downloadclassname,
  downloadtext,
  downloadhref,
  dataTheme,
}) => {
  return (
    <section data-theme={dataTheme}>
      <div className="container container--md margin-top--lg margin-bottom--lg margin---none">
        <div className="col col--12">
          <div className="text--component text--center">
            <h2 className="margin-bottom--sm">{title}</h2>
            <p>{text}</p>
            {download && (
              <Button
                dataGoal={downloaddataGoal}
                href={downloadhref}
                classname={downloadclassname}
                text={downloadtext}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Text
