import React from 'react';
import Layout from '../../components/layout';
import HeroText from '../../components/sections/heroText/heroText';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import EmployersGrid from '../../components/sections/employersGrid/employersGrid';
import Text from '../../components/sections/text/text';
import ImageText from '../../components/sections/imageText/imageText';
import EmployersSlider from '../../components/sections/employersSlider/employersSlider';
import SuccessCards from '../../components/sections/successCards/successCards';
import WorkableFeed from '../../components/sections/workableFeed/workableFeed';

const IndexPage = () => (
  <Layout
    headerWitdh="header"
    headerStyle="flex--space-between"
    showFooter
    ButtonHeader
  >
    <Seo
      title="Joint Academy"
      language="sv"
      description="Together we build better healthcare. Join us!"
    />
    <Segment id="ezM1zWXFhQ0Lzjqy68oi4yaRFGE96phW" />
    <HeroText
      dataTheme="fade"
      title="Together we build better healthcare"
      text="Joint Academy is a healthcare company backed by science. We offer a clinically-proven digital treatment for chronic joint pain, a condition that affects millions of people worldwide. Join us in building better healthcare!"
      download
      downloadtext="View open positions"
      downloaddataGoal="Store Viewed"
      downloadhref="#jobs"
      downloadclassname="btn btn--primary btn--md"
      heroTextMargin="margin-top--lg"
    />
    <EmployersGrid
      employersImageGrid
      filename="people.jpg"
      alt="employersGrid"
    />
    <Text
      title="Life-changing technology"
      text="As a part of our product and engineering team, you get to use technology to build a product that improve the quality of life for thousands of people suffering from chronic joint pain."
    />
    <ImageText
      imgSize="col--12 col--lg-5"
      Filename="impact.jpg"
      borderShow="borderShow"
      alt="blue california"
      Position="rowRevers"
      title="Meaningful impact"
      text="Joint Academy exists because millions of people all over the world suffer from joint pain. We know that there is a better solution than pills and surgery and firmly believe that every single person living with joint pain has the right to appropriate, sustainable care – when and where they need it."
    />
    <ImageText
      imgSize="col--12 col--lg-5"
      Filename="leif_jakob.jpg"
      borderShow="borderShow"
      alt="blue california"
      title="Swedish start-up with global ambitions"
      text="Founded in Malmö 2014 by father and son Dahlberg, we have now grown to become a global organisation operating in multiple international markets and with more than 100 employees from 25 different nationalities."
    />
    <ImageText
      imgSize="col--12 col--lg-5"
      Filename="app1.jpg"
      borderShow="borderShow"
      alt="blue california"
      Position="rowRevers"
      title="More than code"
      text="We are looking for developers who make sure that we not only code and deploy but also think about scalability, performance, monitoring and security. We also encourage everyone to contribute with ideas around user experience and accessibility, to make the patient experience even better."
    />
    <Text
      dataTheme="electric-blue"
      title="Power to you and your peers"
      text="Although every person brings their own unique competence, we are stronger together. We believe strongly in the power of diversity, and the power of teamwork. You will get to work in a cross-functional product team together with other developers, designers, UX researchers, data analysts and product managers. All product teams work closely with our internal Science and Medical Operations team, ensuring that our product meets the highest medical and science evidence standards."
      download
      downloadtext="View open positions"
      downloadhref="#jobs"
      downloadclassname="btn btn--neg btn--md"
    />
    <EmployersSlider />
    <SuccessCards
      title="We set you up for success"
      cards={[
        {
          alt: 'App',
          Filename: 'flexibility@2x.png',
          title: 'Flexibility',
          text:
              'Work from our centrally located Malmö office, designated co-working spaces or from the comfort of your own home. Or why not a mix of both worlds?',
        },
        {
          alt: 'Insurence',
          Filename: 'grow@2x.png',
          title: 'Grow as we grow',
          text:
              'We offer quarterly hack days, monthly knowledge sharing talks, one hour per week to spend on professional learning and a dedicated budget for competence development.',
        },
        {
          alt: 'Pt',
          Filename: 'back@2x.png',
          title: 'We got your back',
          text:
              'We make sure that you are set up with a comprehensive insurance package in case something happens to you, as well as an occupational pension plan.',
        },
        {
          alt: 'App',
          Filename: 'wellness@2x.png',
          title: 'Promoting wellness',
          text:
              'We empower employees to regular physical activity and health promoting activities. You get to spend two working hours per week on physical activity. ',
        },
        {
          alt: 'Insurence',
          Filename: 'active@2x.png',
          title: 'Stay active',
          text:
              'You will also be granted a wellness allowance and have the opportunity to engage in a variety of wellness related activities.',
        },
        {
          alt: 'Pt',
          Filename: 'give@2x.png',
          title: 'Give something back',
          text:
              'At Joint Academy, you can spend one working day per year volunteering for something close to your heart.',
        },
      ]}
      widthIcons="widthIcons"
      renderDownloadButton
      ctaButtonText="Get started today"
      ctaDataGoal="Store Viewed"
      ctaHref="https://us-onboarding.jointacademy.com/"
    />
    <WorkableFeed dataTheme="ice-blue" />
  </Layout>
);

export default IndexPage;
